<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}
      </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="downloadFile()"><i style="font-size:2rem" class="icon ion-ios-download-outline"></i>
        </ion-button>
        <ion-button @click="closeModal()"><i style="font-size:2rem" class="icon ion-ios-close-outline"></i></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content id="pdf-viewer" scroll="false" style="margin-top:0px;width:100%; height:100%" has-bouncing="false">
    <iframe style="position:absolute;top:0;left:0;width:100%;height:100%;" :src="link"></iframe>

  </ion-content>
</template>

<script>
import {IonContent, IonHeader, IonTitle, IonToolbar, modalController} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    title: {type: String,},
    link: {type: String},
  },
  data() {
    return {
      content: 'Content',
    }
  },
  components: {IonContent, IonHeader, IonTitle, IonToolbar},
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    downloadFile() {
      if (this.link.indexOf('#')) {
        let newLink = this.link.split('/#/')[1]
        window.open(newLink, '_blank')
      }
    }
  }

});
</script>
