<template>
  <div id="presentation-list">

    <div v-for="presentation in presentations" :key="presentation.id">
      <div>
        <ion-item detail class="presentation-list-item-modern">
          <ion-row style="width:100%">
            <ion-col size="auto" style="text-align: center">
              <div class="timeslot">
                <ion-icon
                    :name="'time-outline'"
                    class="time-icon"
                ></ion-icon>
                <span class="time"
                      v-show="presentation.show_time && presentation.start_time.split('T')[1]"
                >{{ moment(presentation.start_time).format('HH:mm') }} </span>
              </div>

            </ion-col>
            <ion-col size="10" class="content">
              <div>
                <router-link :to="'/app/presentations/' + presentation.id">
                <span class="w600 title"><span
                    v-if="presentation.code">{{ presentation.code }} - </span><span
                    v-html="presentation.title"></span></span>
                  <span class="speaker-line" v-for="speaker in presentation.speakers"
                        :key="speaker.id">{{ speaker.display_name }}, <span
                      v-if="speaker.city">{{ speaker.city }}</span> <span
                      v-if="speaker.country">({{ speaker.country }})</span>
                </span>
                </router-link>
                <p v-show="presentation.sess_start">{{
                    moment(presentation.sess_start).format('dddd, HH:mm')
                  }}{{ moment(presentation.sess_end).format('- HH:mm') }}
                  <span translate="UHR"></span></p>
                <p v-if="presentation.video_link && presentation.video_link != 'undefined' && showVideo "
                   style="margin-top:5px"><i class="ion-ios-videocam" style="font-size:1.2rem; color:#000"></i><span
                    style="color:#7F7E7E; font-size:1.1rem"> Video available</span></p>
                <p v-if="presentation.slides" style="padding-left: 5px; background-color: #dbdbdb; color:#000">Slides
                  available in presentation preview</p>
                <p v-if="presentation.file_id" style="margin: 5px 0; cursor:pointer" @click="handleFile(presentation.file_id,presentation.file_name,true)">
                  <font-awesome-icon :icon="['fas', 'file-pdf']" class="type-icon"/> &nbsp; Vortrags-PDF verfügbar
                </p>
                <small v-if="presentation.note" style="font-style:italic; color: dimgrey; white-space: normal">
                  “{{ presentation.note }}“
                </small>
              </div>
            </ion-col>
          </ion-row>
        </ion-item>
      </div>
    </div>
  </div>
</template>
<script>
import {
  IonItem,
  IonIcon,
  IonRow,
  IonCol, modalController
} from "@ionic/vue";
import moment from 'moment';
import {addIcons} from "ionicons";
import {timeOutline} from "ionicons/icons";
import {mapActions, mapGetters} from "vuex";
import PdfModal from "@/views/Industry/PdfModal";


addIcons({"time-outline": timeOutline});
export default {
  data() {
    return {}
  },
  components: {
    IonItem,
    IonIcon,
    IonRow,
    IonCol
  },
  props: ['presentations', 'showVideo'],
  created() {
    this.moment = moment;
    console.log('presentations')
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    getPDFLink(link, rewrite) {
      let reslink = link;
      if (rewrite) {
        reslink = 'https://fileserver.s3-de-central.profitbricks.com/' + link;//TEST
      }
      return reslink;
    },

    async handleFile(file, name) {
      let link = this.getPDFLink(file, true);
      let viewerLink = 'https://pdf.abstractserver.com/viewer/#/' + link;
      console.log(viewerLink)

      this.modal = await modalController
          .create({
            component: PdfModal,
            cssClass: 'pdf-modal',
            componentProps: {
              title: name,
              link: viewerLink
            },
          })


      let trackData = {
        user: this.user,
        item: {name: name, id: file},
        event: 'viewed',
        type: 'document',
        itemId: file,
      }
      this.trackEvent(trackData);
      return this.modal.present();
    },
  },


};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
<style lang="scss">

@media (min-width: 768px) {
  .pdf-modal {
    --width: 80%;
    --height: 80%;
  }
}

</style>

