<template>

  <div class="list" style="display:flex; flex-flow:row wrap; margin-left:8px;">
      <div v-for="person in persons"  :key="person.id">
      <router-link :to="'/app/persons/' + person.id" class="wcpt-person-list-item shadow  "  v-if="!person.isLetter">
       <img v-if="!person.vitae_picture" src="@/assets/icon/avatar.png">
       <img v-if="person.vitae_picture" :src="'https://fileserver-188ab.kxcdn.com/file/' + person.vitae_picture.split('/')[4]"
                             style="object-fit:cover">
       <div class="person-item-content">
            <div class="person-name">{{person.display_name}}</div>
            <div class="person-country">({{person.city}}, {{person.country}})</div>
       </div>
      </router-link>
<div class="web-person-divider" ng-if="person.isLetter" >
          <h1>{{person.letter}}</h1>
        </div>
      </div>
    </div>

</template>

<script>


export default {
  data(){
    return{
    }
  },
  props: ['persons'],
  
};
</script>
